<i18n src="./translations"></i18n>

<template>
  <PdpTabBase
    class="pdp-tab-gpsr"
    :headline="$t('pdp-tab-gpsr.headline')"
    :is-expanded="activeTab"
  >
    <div v-for="[key, item] in Object.entries(gpsr)" :key="key" class="row">
      <h3>{{ item.title }}</h3>
      <p>{{ item.name }}</p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="address" v-html="item.address"></p>
      <p>{{ item.description }}</p>
      <p>{{ item.footer }}</p>
    </div>
  </PdpTabBase>
</template>

<script>
import PdpTabBase from 'Components/01-atoms/pdp-tab-base/PdpTabBase';

export default {
  name: 'PdpVehicleTypesTab',
  components: { PdpTabBase },
  props: {
    gpsr: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    activeTab: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.pdp-tab-gpsr {
  .row {
    margin-bottom: var(--space-3);
    line-height: var(--space-3);

    h3 {
      font-weight: bold;
      margin-bottom: var(--space-1);
    }

    .address {
      margin-bottom: var(--space-1);
    }
  }
}
</style>
